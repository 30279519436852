import axios from '@axios';
import evaluationRequestStore from '../evaluationRequest/evaluationRequestStore';
import { jsonToFormData } from './functions';

export default {
  namespaced: true,
  root: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEvaluationRequest(ctx, { id }) {
      return evaluationRequestStore.actions.fetchEvaluationRequest(ctx, { id }, {root:true});
    },
    fetchAreas(ctx, { id }) {
      return evaluationRequestStore.actions.fetchAreas(ctx, { id }, {root:true});
    },
    fetchAllAreas(ctx) {
      return evaluationRequestStore.actions.fetchAllAreas(ctx, null, {root:true});
    },
    fetchEvaluations(ctx, queryParams) {
      const { id } = queryParams;
      // FetchEvaluations is used to retrieve a specific request evaluation if the id is passed otherwise all evaluations based on role
      const url = id ? `/api/evaluation/evaluation-request/${queryParams.id}` : '/api/evaluation';
      return new Promise((resolve, reject) => {
        axios
          .get(url, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchEvaluation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/evaluation/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEditEvaluation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/evaluation/${id}/edit`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createCopy(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/evaluation/${id}/copy`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEvaluation(ctx, { evaluationData, id }) {

      const formData = jsonToFormData(evaluationData);
      formData.append('_method', 'PUT');

      return new Promise((resolve, reject) => {
        axios
          .post(`/api/evaluation/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteEvaluation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/evaluation/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addEvaluation(ctx, evaluationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/evaluation', evaluationData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFeatures(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/property-type/${id}/features`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllFeatures(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-type/all-features')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadEvaluation(ctx, { id, report_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/evaluation/${id}/download?report_id=${report_id}`, {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    submitForApprovalEvaluation(ctx, { evaluationData, id }) {
      const formData = jsonToFormData(evaluationData);
      formData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/evaluation/${id}/submit`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    aproveEvaluation(ctx, { evaluationData, id }) {
      const formData = jsonToFormData(evaluationData);
      formData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/evaluation/${id}/aprove`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    draftEvaluation(ctx, evaluationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/evaluation/draft', evaluationData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEvaluationsByQuery(ctx, query) {
      // FetchEvaluations is used to retrieve a specific request evaluation if the id is passed otherwise all evaluations based on role
      const url = 'api/evaluations/advanced-search';
      return new Promise((resolve, reject) => {
        axios
          .post(url, query)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchCitiesByRegion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/region/${id}/cities`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRegions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/region/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCities(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx, { id }) {
      console.log(id);
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContractOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportReport(ctx, { data, mime }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/evaluation/report', data, {
            responseType: 'blob',
            headers: {
              Accept: mime,
            },
          }) // Pass data in the request body
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllTemplates(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/template')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllModels(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/research')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEvaluationRequestsTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/config/evaluationRequests.types')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

  },
};
